import { createStore } from 'vuex';

export default createStore({
  state: {
    isUserLogin: true,
    userInfo: {
      userId: '',
      name: '',
      email: '',
      isSuper: false,
    },
    centerInfo: {
      name: '',
      address: '',
      phoneNumber: '',
    },
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    },
    getCenterInfo(state) {
      return state.centerInfo;
    },
  },
  mutations: {
    userLogin(state, userInfo) {
      state.isUserLogin = true;
      state.userInfo.userId = userInfo.userId;
      state.userInfo.name = userInfo.name;
      state.userInfo.email = userInfo.email;
      state.userInfo.isSuper = userInfo.isSuper;
      state.centerInfo.name = userInfo.name;
      state.centerInfo.address = userInfo.address;
      state.centerInfo.phoneNumber = userInfo.phoneNumber;
    },
    userLogout(state) {
      state.isUserLogin = false;
      state.userInfo.userId = '';
      state.userInfo.name = '';
      state.userInfo.email = '';
      state.userInfo.isSuper = false;
      state.centerInfo.name = '';
      state.centerInfo.address = '';
      state.centerInfo.phoneNumber = '';
      localStorage.removeItem('ACCESS_TOKEN');
    },
  },
  actions: {},
  modules: {},
});
