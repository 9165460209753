<template>
  <div v-if="isMounted === true" class="home flex h-full w-full bg-hg-box002">
    <!-- SLIDE MENU start -->
    <div
      class="shrink-0 h-full w-[280px] hidden sm:flex bg-hgcare-primaryPressed flex-col pt-[30px] pb-[60px] text-hg-default2 text-h3-semibold"
    >
      <div class="flex flex-col px-[42px] pb-[33px]">
        <p class="pb-[6px] text-hg-default2 text-body2-medium">
          {{ centerName }}
        </p>
        <router-link to="/"
          ><img
            class="cursor-pointer"
            src="@/assets/logos/header.svg"
            alt="허그케어"
        /></router-link>
      </div>
      <router-link to="/">
        <div class="py-[20px] px-[42px] bg-hgcare-primary003 cursor-pointer">
          <div class="flex gap-[8px] items-center">
            <img src="@/assets/icons/home.svg" alt="홈" />
            <p>홈</p>
          </div>
        </div>
      </router-link>
      <router-link to="/reserve">
        <div class="py-[20px] px-[42px] hover:bg-hgcare-primary cursor-pointer">
          <div class="flex gap-[8px] items-center">
            <img src="@/assets/icons/reserve.svg" alt="예약 관리" />
            <p>예약 관리</p>
          </div>
        </div>
      </router-link>
      <router-link to="/history">
        <div class="py-[20px] px-[42px] hover:bg-hgcare-primary cursor-pointer">
          <div class="flex gap-[8px] items-center">
            <img src="@/assets/icons/history.svg" alt="히스토리" />
            <p>히스토리</p>
          </div>
        </div>
      </router-link>
      <router-link v-if="userInfo.isSuper === true" to="/center">
        <div class="py-[20px] px-[42px] hover:bg-hgcare-primary cursor-pointer">
          <div class="flex gap-[8px] items-center">
            <img src="@/assets/icons/center.svg" alt="센터 관리" />
            <p>센터 관리</p>
          </div>
        </div>
      </router-link>
      <div
        @click="logout()"
        class="py-[20px] px-[42px] mt-auto hover:bg-hgcare-primary cursor-pointer"
      >
        <div class="flex gap-[8px] items-center">
          <img src="@/assets/icons/logout.svg" alt="로그아웃" />
          <p>로그아웃</p>
        </div>
      </div>
    </div>
    <!-- SLIDE MENU end -->
    <div class="w-full h-full flex flex-col justify-between overflow-y-auto">
      <!-- Main Contents start -->
      <div
        class="w-full grid grid-cols-2 gap-[30px] pl-[40px] pr-[28px] py-[36px]"
      >
        <div
          class="bg-hg-box001 shadow-hg px-[22px] pt-[30px] pb-[40px] col-span-2"
        >
          <div class="flex flex-col items-center">
            <p class="text-body1-semibold text-hgcare-primary002">Today</p>
            <p class="mt-[10px] text-title-36">
              {{ summaryData.todayDate }}
            </p>
            <div
              class="mt-[60px] w-full flex gap-[11%] justify-center text-center"
            >
              <div class="flex flex-col items-center">
                <p
                  class="text-title-52"
                  :class="[
                    summaryData.todayTotal === 0 ? 'text-hg-disabled' : '',
                  ]"
                >
                  {{ summaryData.todayTotal }}
                </p>
                <p class="text-body2-medium text-hg-info">
                  오늘 예정 상담수<br />(전체)
                </p>
              </div>
              <div class="flex flex-col items-center">
                <p
                  class="text-title-52"
                  :class="[
                    summaryData.todayTeacher === 0
                      ? 'text-hg-disabled'
                      : 'text-hg-primary',
                  ]"
                >
                  {{ summaryData.todayTeacher }}
                </p>
                <p class="text-body2-medium text-hg-info">
                  오늘 예정 상담수<br />(선생님)
                </p>
              </div>
              <div class="flex flex-col items-center">
                <p
                  class="text-title-52"
                  :class="[
                    summaryData.todayStudent === 0
                      ? 'text-hg-disabled'
                      : 'text-hgfriends-primary',
                  ]"
                >
                  {{ summaryData.todayStudent }}
                </p>
                <p class="text-body2-medium text-hg-info">
                  오늘 예정 상담수<br />(학생)
                </p>
              </div>
              <div class="flex flex-col items-center">
                <p
                  class="text-title-52"
                  :class="[
                    summaryData.todayCancel === 0 ? 'text-hg-disabled' : '',
                  ]"
                >
                  {{ summaryData.todayCancel }}
                </p>
                <p class="text-body2-medium text-hg-info">변경/취소</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-hg-box001 col-span-2 xl:col-span-1 shadow-hg px-[22px] pt-[30px] pb-[40px]"
        >
          <div class="flex justify-between items-center">
            <p class="text-h2-bold">오늘 내 일정</p>
            <button @click="this.$router.push('/reserve')" class="button-edit">
              더보기
            </button>
          </div>
          <div
            class="mt-[12px] py-[12px] px-[15px] text-hg-info bg-hg-box004 flex justify-between items-center text-center"
          >
            <p class="w-[95px]">시간</p>
            <p class="w-[95px]">유형</p>
            <p class="w-[95px]">예약자</p>
            <p class="w-[95px]">기타</p>
            <p class="w-[122px]">상담방입장</p>
          </div>
          <div class="mx-[15px] flex flex-col divide-y divide-hg-border">
            <div
              v-if="todayVideoChatList.length == 0"
              class="my-[60px] text-center"
            >
              <p class="text-h3-semibold text-hg-disabled">
                오늘 예약된 일정이 없습니다
              </p>
            </div>
            <div
              v-for="(todayVideoChatItem, i) in todayVideoChatList"
              :key="i"
              class="h-[64px] flex justify-between items-center text-body2-medium text-center"
            >
              <p class="w-[95px]">{{ todayVideoChatItem.time }}</p>
              <p class="w-[95px]">{{ todayVideoChatItem.userType }}</p>
              <p class="w-[95px]">{{ todayVideoChatItem.userName }}</p>
              <p class="w-[95px]">{{ todayVideoChatItem.etcInfo }}</p>
              <button
                class="button-enter"
                :disabled="
                  !(
                    todayVideoChatItem.isMyVideoChat &&
                    todayVideoChatItem.canEnter
                  )
                "
                @click="
                  todayVideoChatItem.isMyVideoChat &&
                    todayVideoChatItem.canEnter &&
                    enterVideoChat(todayVideoChatItem.idx)
                "
              >
                상담방 입장
              </button>
            </div>
          </div>
        </div>
        <div
          class="bg-hg-box001 col-span-2 xl:col-span-1 shadow-hg px-[22px] pt-[30px] pb-[40px]"
        >
          <div class="flex justify-between items-center">
            <p class="text-h2-bold">
              센터
              <span class="pl-[4px] text-hg-sub text-body3-medium">
                센터 상담가능 시간</span
              >
            </p>
            <button @click="isModalOpen = true" class="button-edit">
              수정하기
            </button>
          </div>
          <div
            class="mt-[12px] py-[12px] px-[15px] text-hg-info bg-hg-box004 flex justify-evenly items-center text-center"
          >
            <p class="w-[100px]">요일</p>
            <p class="w-[360px]">예약 가능 시간</p>
          </div>
          <div class="mx-[15px] flex flex-col divide-y divide-hg-border">
            <div
              v-for="(centerTimeItems, centerTimeTag, i) in centerTimeTable"
              :key="i"
              class="min-h-[48px] flex justify-evenly items-center text-body2-medium text-center"
            >
              <p class="w-[100px]">{{ centerTimeTag }}</p>
              <p class="w-[360px]">
                <span class="text-hg-info" v-if="centerTimeItems.length === 0"
                  >예약 불가</span
                >
                <span v-for="(item, n) in centerTimeItems" :key="n"
                  ><span v-if="n !== 0">, </span>{{ item }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Main Contents end -->
      <!-- FOOTER start -->
      <div
        class="mt-auto shrink-0 pl-[7%] pr-[3%] py-[20px] w-full h-[150px] bg-hg-sub"
      >
        <div class="w-full h-full flex">
          <div
            class="flex flex-col justify-between text-body3-medium text-hg-disabled"
          >
            <div class="mb-[12px]">
              <img src="@/assets/logos/footer.svg" alt="허그케어" />
            </div>
            <div class="hidden xl:block">
              <p>
                상호명 : (주)이랩ㅣ사업자번호 : 852-88-01728ㅣ문의 전화 :
                010-9695-3180
              </p>
              <p>
                사업장 주소 : 경기 김포시 김포한강10로133번길 127,
                디원지식산업센터 513호
              </p>
              <p>대표자명 : 이혜선, 황예지ㅣ개인정보 보호 관리자 : 최윤복</p>
            </div>
          </div>
          <div class="ml-auto flex flex-col justify-end">
            <p class="text-body4-medium text-hg-disabled">
              Copyright ⓒ 2024 Education-LAB All rights reserved
            </p>
          </div>
        </div>
      </div>
      <!-- FOOTER end -->
    </div>
    <div v-if="isModalOpen === true">
      <div class="modal-bg"></div>
      <div
        class="modal-box-lg flex flex-col items-center shadow-xl"
        @blur="isModalOpen = false"
      >
        <div
          class="w-full h-[76px] shrink-0 bg-hgcare-primary002 flex items-center justify-between pl-[22px]"
        >
          <p class="text-hg-default2 text-h2-bold">센터 설정</p>
          <div
            class="px-[22px] hover:bg-hgcare-primary h-full flex items-center cursor-pointer"
            @click="isModalOpen = false"
          >
            <img src="@/assets/icons/close.svg" />
          </div>
        </div>
        <div class="w-full shrink-0 pt-[30px] pb-[70px] px-[7%]">
          <div class="w-full flex flex-col items-center max-w-[728px] mx-auto">
            <!-- 수업 희망 시간 선택 -->
            <div class="w-full">
              <p class="text-body3-medium text-hg-sub pb-[32px]">
                상담가능 시간 설정
              </p>
              <div class="flex flex-col gap-[16px] w-full">
                <!-- 일요일 ~ 토요일 -->
                <div v-for="(timeCheckItem, Idx) in TimeChecks" :key="Idx">
                  <!-- 구분선 -->
                  <div
                    v-if="Idx !== 0"
                    class="h-[1px] bg-hg-border mb-[16px]"
                  ></div>
                  <div class="flex items-start">
                    <!-- 요일 버튼 -->
                    <div
                      @click="dayOfWeekButtonClick(Idx)"
                      class="mr-[20px] h-[55px] w-[70px] flex justify-center items-center cursor-pointer rounded-[10px] text-body1-semibold bg-hgcare-primary text-hg-default2"
                      :class="{
                        'border border-hg-border !bg-hg-box001 !text-hgcare-primary':
                          timeCheckItem.timeValues.length === 0,
                      }"
                    >
                      <p>{{ timeCheckItem.label }}</p>
                    </div>
                    <!-- 시간 선택 -->
                    <div class="flex flex-col gap-[16px]">
                      <p
                        v-if="timeCheckItem.timeValues.length === 0"
                        class="pl-[16px] my-[13.5px] text-body1-medium text-hg-disabled cursor-default"
                      >
                        {{ Text_RegistMain.no_time }}
                      </p>
                      <div
                        v-for="(timeValue, idx) in timeCheckItem.timeValues"
                        :key="idx"
                        class="flex flex-col"
                      >
                        <div
                          class="flex gap-[16px] items-center scale-in-ver-top"
                        >
                          <select
                            class="my-select"
                            :class="{
                              'border-hg-sysRed': timeValue.invalidCode !== 0,
                            }"
                            :style="{
                              'background-image': 'url(' + expandIcon + ')',
                            }"
                            v-model="timeValue.start"
                            @change="
                              checkInvalidSubmit(), isInvalidTime(Idx, idx)
                            "
                          >
                            <option
                              :value="Text_RegistMain.time_start"
                              selected
                            >
                              {{ Text_RegistMain.time_start }}
                            </option>
                            <option
                              v-for="(TimeListValue, i) in TimeListValues"
                              :key="i"
                              :value="TimeListValue.id"
                            >
                              {{ TimeListValue.value }}
                            </option>
                          </select>
                          <p class="text-h3-semibold cursor-default">
                            {{ Text_RegistMain.to }}
                          </p>
                          <select
                            class="my-select"
                            :class="{
                              'border-hg-sysRed': timeValue.invalidCode !== 0,
                            }"
                            :style="{
                              'background-image': 'url(' + expandIcon + ')',
                            }"
                            v-model="timeValue.end"
                            @change="
                              checkInvalidSubmit(), isInvalidTime(Idx, idx)
                            "
                          >
                            <option :value="Text_RegistMain.time_end" selected>
                              {{ Text_RegistMain.time_end }}
                            </option>
                            <option
                              v-for="(TimeListValue, i) in TimeListValues"
                              :key="i"
                              :value="TimeListValue.id"
                            >
                              {{ TimeListValue.value }}
                            </option>
                          </select>
                          <div
                            @click="
                              deleteTimeValue(Idx, idx), checkInvalidSubmit()
                            "
                            class="ml-[14px] cursor-pointer"
                          >
                            <img :src="Icons.delete" />
                          </div>
                        </div>
                        <p
                          class="text-body2-medium text-hg-sysRed pl-[10px] mt-[6px]"
                          :class="{ hidden: timeValue.invalidCode === 0 }"
                        >
                          {{ TimeCheckInvalidMessages[timeValue.invalidCode] }}
                        </p>
                      </div>
                    </div>
                    <!-- 시간 추가 버튼 -->
                    <div
                      @click="addTimeValue(Idx)"
                      class="ml-auto my-[15px] cursor-pointer"
                    >
                      <img :src="Icons.add" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              @click="submit"
              :disabled="submitDisabled"
              class="mx-auto mt-[30px] button-primary shrink-0"
            >
              설정하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDashboard,
  getTodayVideoChats,
  getTimeTable,
  editTimeTable,
} from '@/apis/home';
import router from '@/router';
import store from '@/store';
export default {
  name: 'HomeView',
  data() {
    return {
      isMounted: false,
      userInfo: {},
      centerName: '',
      isModalOpen: false,
      submitDisabled: true,
      Text_RegistMain: {
        time_start: '시작 시간',
        time_end: '종료 시간',
        to: '~',
        no_time: '가능한 시간 없음',
      },
      Icons: {
        delete: require('@/assets/icons/delete.svg'),
        add: require('@/assets/icons/add.svg'),
      },
      expandIcon: require('@/assets/icons/expand.svg'),
      summaryData: {
        todayDate: '', //'2024.01.09 (월)',
        todayTotal: 0,
        todayTeacher: 0,
        todayStudent: 0,
        todayCancel: 0,
      },
      todayVideoChatList: [
        // {
        //   idx: 1,
        //   time: '10:00',
        //   userType: '프렌즈',
        //   userName: '홍길동',
        //   etcInfo: '신규',
        //   isMyVideoChat: false,
        //   canEnter: true,
        // },
        // {
        //   idx: 2,
        //   time: '10:00',
        //   userType: '프렌즈',
        //   userName: '홍길동',
        //   etcInfo: '신규',
        //   isMyVideoChat: true,
        //   canEnter: true,
        // },
        // {
        //   idx: 3,
        //   time: '10:00',
        //   userType: '프렌즈',
        //   userName: '홍길동',
        //   etcInfo: '신규',
        //   isMyVideoChat: false,
        //   canEnter: true,
        // },
      ],
      centerTimeTable: {
        일요일: [],
        월요일: [],
        화요일: [],
        수요일: [],
        목요일: [], //['10:00-12:00', '14:00-16:00'],
        금요일: [],
        토요일: [],
      },
      TimeListValues: [
        { id: 0, value: '00:00' },
        { id: 1, value: '00:30' },
        { id: 2, value: '01:00' },
        { id: 3, value: '01:30' },
        { id: 4, value: '02:00' },
        { id: 5, value: '02:30' },
        { id: 6, value: '03:00' },
        { id: 7, value: '03:30' },
        { id: 8, value: '04:00' },
        { id: 9, value: '04:30' },
        { id: 10, value: '05:00' },
        { id: 11, value: '05:30' },
        { id: 12, value: '06:00' },
        { id: 13, value: '06:30' },
        { id: 14, value: '07:00' },
        { id: 15, value: '07:30' },
        { id: 16, value: '08:00' },
        { id: 17, value: '08:30' },
        { id: 18, value: '09:00' },
        { id: 19, value: '09:30' },
        { id: 20, value: '10:00' },
        { id: 21, value: '10:30' },
        { id: 22, value: '11:00' },
        { id: 23, value: '11:30' },
        { id: 24, value: '12:00' },
        { id: 25, value: '12:30' },
        { id: 26, value: '13:00' },
        { id: 27, value: '13:30' },
        { id: 28, value: '14:00' },
        { id: 29, value: '14:30' },
        { id: 30, value: '15:00' },
        { id: 31, value: '15:30' },
        { id: 32, value: '16:00' },
        { id: 33, value: '16:30' },
        { id: 34, value: '17:00' },
        { id: 35, value: '17:30' },
        { id: 36, value: '18:00' },
        { id: 37, value: '18:30' },
        { id: 38, value: '19:00' },
        { id: 39, value: '19:30' },
        { id: 40, value: '20:00' },
        { id: 41, value: '20:30' },
        { id: 42, value: '21:00' },
        { id: 43, value: '21:30' },
        { id: 44, value: '22:00' },
        { id: 45, value: '22:30' },
        { id: 46, value: '23:00' },
        { id: 47, value: '23:30' },
        { id: 48, value: '24:00' },
      ],
      TimeChecks: [
        {
          id: 0,
          label: '일',
          timeValues: [],
        },
        {
          id: 1,
          label: '월',
          timeValues: [
            // {
            //   start: '시작 시간',
            //   end: '종료 시간',
            //   invalidCode: 0,
            // },
            // {
            //   start: 1,
            //   end: 6,
            //   invalidCode: 0,
            // },
            // {
            //   start: '시작 시간',
            //   end: '종료 시간',
            //   invalidCode: 0,
            // },
          ],
        },
        {
          id: 2,
          label: '화',
          timeValues: [
            // {
            //   start: 4,
            //   end: 6,
            //   invalidCode: 0,
            // },
          ],
        },
        {
          id: 3,
          label: '수',
          timeValues: [],
        },
        {
          id: 4,
          label: '목',
          timeValues: [],
        },
        {
          id: 5,
          label: '금',
          timeValues: [],
        },
        {
          id: 6,
          label: '토',
          timeValues: [],
        },
      ],
      TimeCheckInvalidMessages: [
        '',
        '종료시간은 시작시간 이후로 선택해주세요.',
        '상담 가능 시간이 너무 짧습니다.',
        '시간이 중복되게 설정할 수 없습니다.',
        '시작 시간과 종료 시간을 마저 선택해주세요.',
      ],
    };
  },
  async mounted() {
    this.centerName = store.state.centerInfo.name;
    this.userInfo = store.state.userInfo;
    this.isMounted = true;
    let dashboardRes = await getDashboard();
    // console.log(dashboardRes);
    this.summaryData = dashboardRes.dashboard.summaryData;
    let todayVideoChatsRes = await getTodayVideoChats();
    // console.log(todayVideoChatsRes);
    this.todayVideoChatList = todayVideoChatsRes.todayVideoChatList;
    let timeTableRes = await getTimeTable();
    // console.log(timeTableRes);
    this.centerTimeTable = timeTableRes.centerTimeTable;

    this.convertTimeTableToTimeChecks();
  },
  methods: {
    logout() {
      store.commit('userLogout');
      // console.log(store.state.userInfo);
      this.$router.replace('/login');
    },
    enterVideoChat(idx) {
      // this.$router.push('/videochat/' + idx);
      this.$router.push({
        name: 'chat',
        state: {
          idx: idx,
        },
      });
    },
    convertTimeTableToTimeChecks() {
      let dayOfWeek = [
        '일요일',
        '월요일',
        '화요일',
        '수요일',
        '목요일',
        '금요일',
        '토요일',
      ];
      this.TimeChecks;
      for (let i = 0; i < dayOfWeek.length; i++) {
        this.TimeChecks[i].timeValues = [];
        for (let j = 0; j < this.centerTimeTable[dayOfWeek[i]].length; j++) {
          let curTimeObj = this.centerTimeTable[dayOfWeek[i]][j].split('-');
          const newItem = {
            start: this.findIdByValueFromTimeListValues(curTimeObj[0]),
            end: this.findIdByValueFromTimeListValues(curTimeObj[1]),
            invalidCode: 0,
          };
          this.TimeChecks[i].timeValues.push(newItem);
        }
      }
      // console.log(this.TimeChecks);
    },
    findIdByValueFromTimeListValues(value) {
      for (let i = 0; i < this.TimeListValues.length; i++) {
        if (this.TimeListValues[i].value === value) {
          return this.TimeListValues[i].id;
        }
      }
      return -1;
    },
    dayOfWeekButtonClick(Idx) {
      if (this.TimeChecks[Idx].timeValues.length === 0) {
        const newItem = {
          start: this.Text_RegistMain.time_start,
          end: this.Text_RegistMain.time_end,
          invalidCode: 0,
        };
        this.TimeChecks[Idx].timeValues.push(newItem);
      } else {
        this.TimeChecks[Idx].timeValues.length = 0;
      }
    },
    addTimeValue(Idx) {
      const newItem = {
        start: this.Text_RegistMain.time_start,
        end: this.Text_RegistMain.time_end,
        invalidCode: 0,
      };
      this.TimeChecks[Idx].timeValues.push(newItem);
    },
    deleteTimeValue(Idx, idx) {
      this.TimeChecks[Idx].timeValues.splice(idx, 1);
    },
    isInvalidTime(Idx, idx) {
      // 3: 중복체크 need to be implemented
      if (
        this.TimeChecks[Idx].timeValues[idx].start ===
          this.Text_RegistMain.time_start ||
        this.TimeChecks[Idx].timeValues[idx].end ===
          this.Text_RegistMain.time_end
      ) {
        return true;
      } else if (
        this.TimeChecks[Idx].timeValues[idx].start >
        this.TimeChecks[Idx].timeValues[idx].end
      ) {
        this.TimeChecks[Idx].timeValues[idx].invalidCode = 1;
        return true;
      } else if (
        this.TimeChecks[Idx].timeValues[idx].end -
          this.TimeChecks[Idx].timeValues[idx].start <
        2
      ) {
        this.TimeChecks[Idx].timeValues[idx].invalidCode = 2;
        return true;
      } else {
        this.TimeChecks[Idx].timeValues[idx].invalidCode = 0;
        return false;
      }
    },
    checkInvalidSubmit() {
      for (let i = 0; i < this.TimeChecks.length; i++) {
        for (let j = 0; j < this.TimeChecks[i].timeValues.length; j++) {
          if (this.isInvalidTime(i, j)) {
            this.submitDisabled = true;
            this.TimeChecks[i].timeValues[j].invalidCode = 4;
            return true;
          }
        }
      }
      this.submitDisabled = false;
      return false;
    },
    async submit() {
      if (this.checkInvalidSubmit()) {
        return;
      }
      let timeTable = {};
      let dayOfWeek = [
        '일요일',
        '월요일',
        '화요일',
        '수요일',
        '목요일',
        '금요일',
        '토요일',
      ];
      for (let i = 0; i < this.TimeChecks.length; i++) {
        timeTable[dayOfWeek[i]] = [];
        for (let j = 0; j < this.TimeChecks[i].timeValues.length; j++) {
          let start =
            this.TimeListValues[this.TimeChecks[i].timeValues[j].start].value;
          let end =
            this.TimeListValues[this.TimeChecks[i].timeValues[j].end].value;
          timeTable[dayOfWeek[i]].push(start + '-' + end);
        }
      }
      //console.log(timeTable);
      let res = await editTimeTable(timeTable);
      //console.log(res);
      this.isModalOpen = false;
      router.go(0);
    },
  },
};
</script>
