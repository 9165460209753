import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import { profile } from '../apis/user.js';
import store from '../store';

const getProfile = () => async (from, to, next) => {
  let accessToken = await localStorage.getItem('ACCESS_TOKEN');
  if (accessToken) {
    let profileRes = await profile();
    if (profileRes.error == true) {
      await localStorage.removeItem('ACCESS_TOKEN');
    } else {
      await store.commit('userLogin', {
        userId: profileRes.userId,
        name: profileRes.name,
        email: profileRes.email,
        isSuper: profileRes.isSuper,
        name: profileRes.centerName,
        address: profileRes.centerAddress,
        phoneNumber: profileRes.centerPhoneNumber,
      });
    }
  }
  return next();
};

const requireAuth = () => async (from, to, next) => {
  let accessToken = await localStorage.getItem('ACCESS_TOKEN');
  if (accessToken == null) return next('/login');
  else return next();
};
const requireSuper = () => async (from, to, next) => {
  let isSuper = await store.state.userInfo.isSuper;
  if (isSuper === true) return next();
  else return next('/');
};

/* const noRequireAuth = () => async (from, to, next) => {
  let accessToken = await localStorage.getItem('ACCESS_TOKEN');
  if (accessToken == null) return next();
  else return next('/login');
}; */

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: [getProfile(), requireAuth()],
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: [getProfile()],
    component: LoginView,
  },
  {
    path: '/reserve',
    name: 'reserve',
    beforeEnter: [getProfile(), requireAuth()],
    component: () =>
      import(/* webpackChunkName: "hugcare" */ '../views/ReserveView.vue'),
  },
  {
    path: '/history',
    name: 'history',
    beforeEnter: [getProfile(), requireAuth()],
    component: () =>
      import(/* webpackChunkName: "hugcare" */ '../views/HistoryView.vue'),
  },
  {
    path: '/center',
    name: 'center',
    beforeEnter: [getProfile(), requireAuth(), requireSuper()],
    component: () =>
      import(/* webpackChunkName: "hugcare" */ '../views/CenterView.vue'),
  },
  {
    path: '/chat',
    name: 'chat',
    beforeEnter: [getProfile(), requireAuth()],
    component: () =>
      import(/* webpackChunkName: "hugcare" */ '../views/VideoChatView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
