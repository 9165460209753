import { post, get } from './common';

export const getDashboard = async () => {
  return get('home/dashboard', true);
};

export const getCenterInfo = async () => {
  return get('home/centerInfo', true);
};

export const getTodayVideoChats = async () => {
  return get('home/todayVideoChats', true);
};

export const getTimeTable = async () => {
  return get('home/timeTable', true);
};

export const editTimeTable = async timeTable => {
  return post(
    'home/timeTable',
    {
      timeTable,
    },
    true
  );
};
