import axios from 'axios';
import { API_BASE_URL } from '../constants/setting';

const makeHeaders = async authRequired => {
  if (authRequired) {
    let accessToken = await localStorage.getItem('ACCESS_TOKEN');

    if (!accessToken) {
      accessToken = '';
    }

    return {
      Authorization: 'Bearer ' + accessToken,
    };
  } else {
    return {};
  }
};

const post = async (uri, body, authRequired) => {
  try {
    let headers = await makeHeaders(authRequired);

    let response = await axios.post(API_BASE_URL + uri, body, {
      headers: headers,
      timeout: 50000,
    });

    return response.data;
  } catch (e) {
    if (e.response?.status) {
      if (e.response?.status == 521 || e.response?.status == 502) {
        return {
          error: true,
          message: 'Failed to connect to server',
        };
      }
    }

    if (e.response?.data) {
      return e.response.data;
    }

    return {
      error: true,
      message: 'Failed to connect to server',
    };
  }
};

const get = async (uri, authRequired) => {
  try {
    let headers = await makeHeaders(authRequired);

    let response = await axios.get(API_BASE_URL + uri, {
      headers: headers,
      timeout: 5000,
    });

    return response.data;
  } catch (e) {
    if (e.response?.status) {
      if (e.response?.status == 521 || e.response?.status == 502) {
        return {
          error: true,
          message: 'Failed to connect to server',
        };
      }
    }

    if (e.response?.data) {
      return e.response.data;
    }

    return {
      error: true,
      message: 'Failed to connect to server',
    };
  }
};

export { post, get };
